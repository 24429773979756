import React from "react";
import { useDispatch } from 'react-redux';
import {updateGroupInfo, setGroupsCards} from "../redux/actions/cards.js";
import $api from "../http/api.js";
import { setDictionary } from '../redux/actions/dictionary.js';
import {useMessage} from '../components/service-components/MessageContext.jsx';

function RemoveCardPopup({cardData, toggleModal, setModalComponent}) {
    const dispatch = useDispatch();
    const {addMessage} = useMessage();
    
    function handleRemoveCardFromGroup(e) {
        e.preventDefault();
        $api.post('/removeCardFromGroup', {card_id: cardData.card_id, group_id: cardData.group_id}).then((result) => {
            toggleModal();
            dispatch(updateGroupInfo(result.data));
        });
    }

    function handleRemoveCardFromDictionary(e) {
        e.preventDefault();
        $api.post(`/delCardFromDictionary/${cardData.card_id}`).then((result) => {
            toggleModal();
            dispatch(setDictionary(result.data));
            addMessage({type: "success", text: "Карточка удалена из словаря"});
        });
    }


    function handleDeleteCard(e) {
        e.preventDefault();
        $api.post('/deleteCard', {card_id: cardData.card_id}).then((result) => {
            toggleModal();
            dispatch(setGroupsCards(result.data.groups_card));
        });
    }

    return (
        <div className="service-popup--wrapper RemoveCardPopup">
            <div className="cards-list">
                <div className="title">Вы уверены, что хотите удалить карточку?</div>
                <div className="cards--item">
                    <div className="cards--item--table">
                        <div className="text">{cardData.word}</div>
                        <div className="text">{cardData.translate}</div>
                        <div className="text">{cardData.transcription}</div>
                    </div>
                </div>
                <div className="buttons">
                <button onClick={toggleModal}>Отмена</button>
                <button onClick={handleRemoveCardFromGroup}>Удалить из набора</button>
                <button onClick={handleRemoveCardFromDictionary}>Удалить из словаря</button>
                <button onClick={handleDeleteCard}>Удалить из базы</button>
            </div>
            </div>
        </div>
    )

}

export default RemoveCardPopup;