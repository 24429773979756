import React, {useState, useEffect, useRef, useMemo} from "react";
import TrainingLayout from './TrainingLayout.jsx';
import $api from '../http/api.js';
import {Link, Navigate, useNavigate} from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {updateTrainingStat} from '../redux/actions/stat-cards.js';
import {PlayAudioCard} from './index.js';


function Training({type, custom}) {
    const dispatch = useDispatch();
    const navigate =  useNavigate();
    const stat = useSelector(state => state.statCards);
    const stateDictionary = useSelector((state) => state.dictionary);
    const customListTraining = localStorage.getItem("customListTraining") ?? [];

    console.log("stat ", stat);
    const {id} = useParams();
    const [cardsInfo, setCardsInfo] = React.useState([]);
    const [cardsData, setCardsData] = React.useState(null);
    const [groupInfo, setGroupInfo] = React.useState([]);
    const [answers, setAnswers] = React.useState([]);
    const [activeAnswer, setActiveAnswer] = React.useState(null);
    const [userInput, setUserInput] = React.useState(false);
    const [incorrectAnswerGiven, setIncorrectAnswerGiven] = React.useState(true);
    const [resultTraining, setResultTraining] = React.useState([]);
    const [statCards, setStatCards] = React.useState([]);
    const [settingsBlock, setSettingsBlock] = useState({
        isVisible: false
    });
    const [settings, setSettings] = React.useState({
        inverse: false,
        handleNextQuestion: true,
        soundRightAnswer: true
    });

    const [trainingState, setTrainingState] = React.useState({
        currentCard: 0,
        isUserChoiceMade: false
    });
    
    const currentCard = trainingState.currentCard;

    const typesTraining = [
        {title: "Иврит - Русский", type: "word-translation"},
        {title: "Русский - Иврит", type: "translation-word"},
        {title: "Картинка - Иврит", type: "image-word"},
    ];

    const rules = {
        "learning": {
            url: "/training/learning/",
            requiredData: ["img", "sound", "trascription"],
            typeCard: ["symbol", "word", "phrase"]
        },
        "word-translation": {
            url: "/training/word-translation/",
            requiredData: [],
            typeCard: ["symbol", "word", "phrase"]
        },
        "translation-word": {
            url: "/training/translation-word/",
            requiredData: [],
            typeCard: ["symbol", "word", "phrase"]
        },
        "image-word": {
            url: "/training/image-word/",
            requiredData: ["img"],
            typeCard: ["symbol", "word", "phrase"]
        },
        "writing": {
            url: "/training/writing/",
            requiredData: [],
            typeCard: ["word", "phrase"]
        }
    }

    const firstType = typesTraining.find(el => el.type === type);
    const secondType = typesTraining.find(el => el.type !== type);

    const [popupHandleChangeQuestion, setPopupHandleChangeQuestion] = React.useState({
        visible: false,
        keyDown: false
    })

    React.useEffect(() => {
        console.log("answers ", answers);
    }, [answers]);


    React.useEffect(() => {
        if (stateDictionary.isLoaded) {
            if (id && !custom) {
                $api.get(`/getCardsGroup/${id}`).then((result) => {
                    setGroupInfo(result.data.groupInfo);
                    let currentCardList = checkRules(result.data.cardsList);
                    if (stateDictionary.deleted_cards.length) {
                        currentCardList = currentCardList.filter(card => !stateDictionary.deleted_cards.includes(card.card_id));
                        setCardsInfo(currentCardList);
                    }
                    else {setCardsInfo(currentCardList);}
                    const generatedAnswers = genAnswers(currentCardList);
                    console.log("generatedAnswers", generatedAnswers);
                    setAnswers(generatedAnswers); 
                });
            }
            else if (!id && custom && customListTraining.length) {
                $api.post(`/getListCards`, {customListTraining: customListTraining}).then((result) => {
                    let currentCardList = checkRules(result.data.cardsList);
                    if (stateDictionary.deleted_cards.length) {
                        currentCardList = currentCardList.filter(card => !stateDictionary.deleted_cards.includes(card.card_id));
                        setCardsInfo(currentCardList);
                    }
                    else setCardsInfo(currentCardList);

                    const generatedAnswers = genAnswers(currentCardList);
                    setAnswers(generatedAnswers); 
                });
            }
        }
    }, [stateDictionary.isLoaded]);

    const genCardsData = useMemo(() => {
    if (cardsInfo.length) {
        return setCardsData(cardsInfo.slice(currentCard, currentCard + 2));
    }
}, [currentCard, cardsInfo]);

    function checkRules(cardsList) {
        let checkedCards = [];
        for (const card of cardsList) {
            if (rules[type].requiredData.every(item => card[item] !== '') && rules[type].typeCard.includes(card.type)) {
                checkedCards.push(card);
            }
        }
        console.log("checkedCards", checkedCards);
        return checkedCards;
    }

    function genAnswers(cardsList) {
        console.log("cardsList in genAnswers", cardsList);
        const answers = cardsList.map((card) => {
            let answer;
            switch (type) {
                case "word-translation":
                    answer = card.translate;
                    break;
                case "translation-word":
                case "image-word":
                    answer = card.word;
                    break;
                default:
                    answer = "";
            }
            console.log("answer in genAnswers", answer);
            return answer;
        });
        return answers;
    }

    const handleNextQuestion = () => {
        if(!activeAnswer) return;
        setUserInput(true);
        if(!incorrectAnswerGiven && currentCard < cardsInfo.length) {
            //setCurrentCard(currentCard + 1);
            //next_question = cardsInfo[currentCard+1];
            //setIncorrectAnswerGiven(true);
        }
        else {
            //setCurrentCard(currentCard);
            //next_question = "end";
        }    
    }

    React.useEffect(()=>{
        console.log("activeAnswer change", settings.handleNextQuestion);
        if (activeAnswer?.isCorrectAnswer || !settings.handleNextQuestion) {
            console.log("activeAnswer test", activeAnswer?.isCorrectAnswer, !settings.handleNextQuestion);
            changeQuestion(activeAnswer);
            setUserInput(false);
        }
    },[activeAnswer]);

    React.useEffect(()=>{
        if (!activeAnswer?.isCorrectAnswer && (settings.handleNextQuestion || popupHandleChangeQuestion.visible) && userInput) {
            changeQuestion(activeAnswer);
            setUserInput(false);
            setPopupHandleChangeQuestion(prev =>({...prev, visible: false}));
        }
    },[userInput]);

    React.useEffect(()=>{
        console.log("change settings", settings);
    },[settings]);


    const changeQuestion = React.useCallback((answer) => {
        if (answer) {
            const {card, ...statCard} = answer;
            console.log("answer", answer);
            statCard.card_id = card.card_id;
            setUserInput(false);

            setTimeout(() => {
                setStatCards(prev => [...prev, statCard]);
                setResultTraining(prevState => (
                    [...prevState, answer]));
                dispatch(updateTrainingStat(answer));
                setTrainingState(prevState => ({
                    ...prevState,
                    currentCard: prevState.currentCard + 1,
                    isUserChoiceMade: true
                }));
            }, 1000);
        }
    },[trainingState]);

    React.useEffect(() => {
        if (currentCard > 0 && currentCard === cardsInfo.length){
            const statData = {"stat_training": statCards};
            $api.post('/user/setUserStatCards', statData).then((response) => {
                console.log('Успешно отправлено:', response.data);
            });
        }
    }, [currentCard]);

    React.useEffect(()=> {
        if(!activeAnswer) return;
        if (activeAnswer?.isCorrectAnswer === false && settings.handleNextQuestion) {
            setTimeout(()=>   setPopupHandleChangeQuestion(prev =>({...prev, visible: true})), 1000)
                }
    },[activeAnswer]);

    const repeatTraining = () => {
        setTrainingState({
            currentCard: 0,
            isUserChoiceMade: false
        });
        setStatCards([]);
        setResultTraining([]);
    }

    const handleTypeTraining = () => {
        setResultTraining([]);
        setTrainingState({
            currentCard: 0,
            isUserChoiceMade: false
        });
        setStatCards([]);
            setAnswers(cardsInfo.map((obj) => {
                let answer;
                switch (secondType.type) {
                    case "word-translation":
                        answer = obj.translate;
                        break;
                    case "translation-word":
                        answer = obj.word;
                        break;
                    default:
                        answer = "";
                }
                return answer;
            }));
            if (id && !custom) navigate(`/training/${secondType?.type}/${id}`);
            else navigate(`/training/${secondType?.type}/custom`);

    }

    return (
        <div className={"block-cards training " + type} tabIndex={0} onKeyDown={handleNextQuestion}>
        <div className="card--wrapper">
            <div className="cards-info">
                <div className="title">Набор: <Link to={`/group/${id}`}><span>{groupInfo.group_name}</span></Link></div>
                <div className="settings-panel" tabIndex={ 0 } onBlur={(e)=> {!e.currentTarget.contains(e.relatedTarget) && setSettingsBlock(prev => ({...prev, isVisible: false}))}}>
                    <i className={'bx bx-cog' + (settingsBlock.isVisible ? ' active' : '')} onClick={()=>setSettingsBlock(prev => ({...prev, isVisible: !prev.isVisible}))}></i>
                    {settingsBlock.isVisible &&
                    <div className="settings--container">
                        <div className="item">
                            <label htmlFor="order">Справа-налево </label><input type="checkbox" id="order" name="order" checked={settings.inverse} onChange={() => setSettings(prev => ({...prev, inverse:!prev.inverse}))}/>
                        </div>
                        <div className="item">
                            <label>Ручная смена карточки при неправильном ответе </label><input type="checkbox" id="handleNextQuestion" name="handleNextQuestion" checked={settings.handleNextQuestion} onChange={() => setSettings(prev => ({...prev, handleNextQuestion:!prev.handleNextQuestion}))}/>
                        </div>
                        <div className="item">
                            <label>Проигрывание озвучки при правильном ответе</label><input type="checkbox" id="soundRightAnswer" name="soundRightAnswer" checked={settings.soundRightAnswer} onChange={() => setSettings(prev => ({...prev, soundRightAnswer:!prev.soundRightAnswer}))}/>
                        </div>
                    </div>}
                </div>
                <div className="count-cards">
                    <div className="count-cards--content"><span name="count_done">{currentCard < cardsInfo.length ? currentCard+1 : currentCard}</span>/<span name="count_all">{cardsInfo.length}</span></div>
                </div>
            </div>
            {currentCard < cardsInfo.length && cardsData ? (
                <>
                    <div className="type-training"><span className="active">{typesTraining.find(item => item.type === type)?.title}</span></div>
                    <TrainingLayout cardsData = {cardsData} answers={answers}  isUserChoiceMade={trainingState.isUserChoiceMade} type={type} settings={settings} /* changeQuestion={changeQuestion} */ setActiveAnswer={setActiveAnswer}/>
                </>
            ) : currentCard && cardsInfo.length ? (
                <div className="result">
                    <h3>Ваш результат:</h3>
                    <span>{`Из ${cardsInfo.length} карточек вы правильно отметили ${resultTraining.filter(answer=>answer.isCorrectAnswer===true) ? resultTraining.filter(answer=>answer.isCorrectAnswer===true).length : 0}`}</span>
                    {resultTraining.filter(answer=>answer.isCorrectAnswer===true).length == cardsInfo.length && (
                        <div className="result--text">Отличный результат!</div>
                    )}
                    {resultTraining.filter(answer=>answer.isCorrectAnswer===true).length >= Math.round(cardsInfo.length/2) && resultTraining.filter(answer=>answer.isCorrectAnswer===true).length <= cardsInfo.length-1 && (
                        <div className="result--text">Вы неплохо справились! Продолжайте в том же духе!</div>
                    )}
                    {resultTraining.filter(answer=>answer.isCorrectAnswer===true).length < Math.round(cardsInfo.length/2) && (
                        <div className="result--text">Продолжайте тренировки и ваши результаты будут лучше!</div>
                    )}
                    <div className="result--list">
                        <div className="wrong-items">{
                        resultTraining.filter(answer=>answer.isCorrectAnswer!==true).map((item) => 
                        ( <div className="wrong-item" style={settings.inverse ? {flexDirection: "row-reverse"} : {}}>
                            <div>{item.card["word"]}</div>
                            <PlayAudioCard sound={item.card["sound"]}/>
                            <div>{item.card["transcription"].length > 0 && "[" + item.card["transcription"] + "]"}</div>
                            <div>{item.card["translate"]}</div>
                          </div>
                        ))}</div>
                        <div className="correct-items">{
                        resultTraining.filter(answer=>answer.isCorrectAnswer===true).map((item) => 
                        ( <div className="correct-item" style={settings.inverse ? {flexDirection: "row-reverse"} : {}}>
                            <div>{item.card["word"]}</div>
                            <PlayAudioCard sound={item.card["sound"]}/>
                            <div>{item.card["transcription"].length > 0 && "[" + item.card["transcription"] + "]"}</div>
                            <div>{item.card["translate"]}</div>
                        </div>
                        ))}</div>
                    </div>
                    <div className="btn--list--wrapper">
                        <div onClick={repeatTraining} className="btn">Попробовать снова</div>
                        {type !== "image-word" && (
                            <div onClick={handleTypeTraining} className="btn">Сменить тренировку</div>
                        )}
                    </div>
                </div>
            ) : (

                    <div className="result">
                        <span>Загрузка...</span>
                    </div>
                )}
        </div>
        {popupHandleChangeQuestion.visible && (
            <div className="popupHandleNextQuestion">
            <div className="popupInfo">
                <div className="title">Нажмите любую клавишу или кликните по кнопке</div>
                <div className="description">Вы можете отключить ручной переход при неправильном ответе в настройках тренировки</div>
                <div className="notShowAnymore">
                    <label>Не показывать больше</label>
                    <input type="checkbox"/>
                </div>
            </div>
            <div className="popupBtn">
                <div className="btn blue-btn" onClick={handleNextQuestion}>Далее</div>
            </div>
        </div>
        )}
    </div>
    )
}

export default Training;