import React, {useState} from "react";
import {Link, useLocation} from 'react-router-dom';
import CheckUserRole from "../components/service-components/CheckUserRole.jsx";
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import $api from '../http/api';
import {setGroupsCards} from '../redux/actions/cards.js';
import { setDictionary } from '../redux/actions/dictionary';
import {useMessage} from '../components/service-components/MessageContext.jsx';

function GroupsCard({data = null, page = null}) {
    const [groupsData, setGroupsData] = useState([]);
    const currentPage = useLocation();

    const dispatch = useDispatch();
    const stateGroups = useSelector((state) => state.groupCards.groups);

    const stateDictionary = useSelector((state) => state.dictionary);

    const {addMessage} = useMessage();

    function delGroupFromDictionary(groupID) {
        $api.post(`/delGroupFromDictionary/${groupID}`)
        .then(result => {
            if (result.status == 200) {
                addMessage({type: "success", text: "Набор удален из словаря"});
                dispatch(setDictionary(result.data));
            }
        })
        .catch(error => {
            const text = error.response?.data?.message ? error.response.data.message : error.message;
            addMessage({type: "error", text: text});
        });
    }

    /* React.useEffect(() => {
        if (!stateDictionary.isLoaded) {
            $api.get("/getUserDictionary").then((result) => {
                dispatch(setDictionary(result.data));
            });
        }
    }, []); */

    React.useEffect(() => {
        if (!data) {
            if(stateGroups.length === 0){
                $api.post("/getGroupsCards").then((result) => {
                    dispatch(setGroupsCards(result.data.groups_card));
                    setGroupsData(result.data.groups_card)});
            }
            else {setGroupsData(stateGroups)}
        }
        else {
            setGroupsData(data);
        }
    }, [data]);

    return (
        <div className="groups--list">
            {groupsData.map((group) => (
            <Link to={`/group/${group.group_id}`}>
                <div className="group--item" style={{backgroundImage: `url(${group.img})`}}>
                    {stateDictionary.groups.findIndex(x => x.group_id == group.group_id) !== -1 && currentPage.pathname == `/library` && 
                    <div className="in-dictionary"><i class='bx bxs-bookmark'></i></div>}
                    {page === "dictionary" && (
                        <div className="delete" onClick={(e)=> {e.stopPropagation();e.preventDefault();delGroupFromDictionary(group.group_id)}}><i data-tooltip="Удалить из словаря" class='bx bxs-x-square red f-36'></i></div>
                    )}
                </div>
                <div className="name"><span>{group.group_name}</span></div>
                <div className="countCard"><span>Карточек: </span><span>{group?.cards?.length}</span></div>
            </Link>
            ))}
        </div>
    );
}

export default GroupsCard;