
const dictionaryInitialState = {
    groups: [],
    cards: [],
    deleted_cards: [],
    temp_group: [],
    isLoaded:false,
}

const dictionaryReducer = (state = dictionaryInitialState, action) => {
    switch(action.type) {
        case "SET_DICTIONARY":
            console.log("Dictionary. Обработка данных в Redux:", action);
            return {
                ...state,
                groups: action.payload.groups,
                cards: action.payload.cards,
                deleted_cards: action.payload.deleted_cards,
                isLoaded: true
            }
        case "SET_TEMP_GROUP":
            return {
                ...state,
                temp_group: action.payload.temp_group
            }

        default: return state;
    }
};

export default dictionaryReducer;