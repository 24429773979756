import React, { useEffect } from "react";
import ModalPopup, {toggleModal} from "./ModalPopup.jsx";
import {TrainingPanel} from "../components/";
import RemoveCardPopup from "./RemoveCardPopup.jsx";
import EditCardForm from "./EditCardForm.jsx";
import { useSelector, useDispatch } from 'react-redux';
import { setUserStatCards } from '../redux/actions/stat-cards';
import { setDictionary, setTempGroup } from '../redux/actions/dictionary.js';
import $api from '../http/api.js';
import {useMessage} from '../components/service-components/MessageContext.jsx';

function CardsList({cardsList, groupID, setEvent, offset= 0}) {
    const [isPlaying, setIsPlaying] = React.useState(false);
    const [audio, setAudio] = React.useState(null);
    const [audioData, setAudioData] = React.useState(null);
    const [visibleCustom, setVisibleCustom] = React.useState(false);
    const [customListCards, setCustomListCards] = React.useState([]);

    const dispatch = useDispatch();

    const user = useSelector(state => state.user);

    const stateDictionary = useSelector((state) => state.dictionary);
    const stateStatCards = useSelector(state => state.statCards);

    const {addMessage} = useMessage();

    const handleCustomTraining = (checked, card) => {
        if (checked) {
            console.log("checkbox checked");
            setCustomListCards(prev => {
                const newArr = [...prev, card];
                return [...new Set(newArr)]
            })
        }
        else {
            setCustomListCards(prev => {
                const newArr = prev.filter(item => item.card_id !== card.card_id)
                return newArr;
            })
        }
    }

    React.useEffect(()=> {
        const customListCardsIDs = customListCards.map(card => card.card_id);
        localStorage.setItem("customListTraining", JSON.stringify(customListCardsIDs));
    }, [customListCards]);

    React.useEffect(()=>{
        if (!visibleCustom) {
            setCustomListCards([]);
            // localStorage.setItem("customListTraining", JSON.stringify([]));
        }
    },[visibleCustom])

    React.useEffect(() => {
        if (!stateStatCards.isLoaded) {
            $api.post('/user/getUserStatCards').then((result) => {
                dispatch(setUserStatCards(result.data.cardsStat));
            });
        };

        const newAudio = new Audio();
        setAudio(newAudio);
    
        const handlePlay = () => {
            setIsPlaying(true);
        };
    
        const handlePause = () => {
          setIsPlaying(false);
        };
    
        // Добавляем обработчики событий для аудио
        if (newAudio) {
          newAudio.addEventListener('play', handlePlay);
          newAudio.addEventListener('pause', handlePause);
        }
    
        return () => {
          // Удаляем обработчики событий при размонтировании компонента
          if (newAudio) {
            newAudio.removeEventListener('play', handlePlay);
            newAudio.removeEventListener('pause', handlePause);
            newAudio.pause();
          }
          setAudio(null);
        };
      }, []);

    const[openModal, setOpenModal] = React.useState(false);
    const [modalComponent, setModalComponent] = React.useState(null);

    const handleEditCard = (e) => {
        const card = e.target.closest(".cards--item");
        const cardID = card.dataset.cardId;
        const cardData = cardsList.find(card => card.card_id == cardID);
        setModalComponent({component: <EditCardForm cardData={cardData} setEvent={setEvent}/>, title: 'Изменить карточку'});
        toggleModal(openModal, setOpenModal);
    };

    const handleAddCardToDictionary = (cardID) => {
        if (stateDictionary.cards.find(dicCard => dicCard.card_id === cardID)) {
            return;
        }
        $api.post('/addCardToDictionary', {card_id: cardID})
            .then(result => {
                if (result.status == 200) {
                    addMessage({type: "success", text: "Карточка добавлена в словарь"});
                    dispatch(setDictionary(result.data));
                }
            })
            .catch(error => {
                const text = error.response?.data?.message ? error.response.data.message : error.message;
                addMessage({type: "error", text: text});
            })
        
    }

    const handleRemoveCard = (e) => {
        const card = e.target.closest(".cards--item");
        const cardID = card.dataset.cardId;
        const cardData = cardsList.find(card => card.card_id == cardID);
        setModalComponent({component: <RemoveCardPopup cardData={{...cardData, group_id: groupID}} />, title: 'Удалить карточку', widthContent: true});
        toggleModal(openModal, setOpenModal);
    };

    function playingSound(srcAudio, word) {
        audio.src = srcAudio;
        if (audio) {
            if (isPlaying) {
              audio.pause();
              setIsPlaying(false);
            } else {
                setAudioData({word: word});
                audio.play();
            }
          }
    };

    function countProgress(cardObj){
        if (!cardObj) return 0;
        const balls=cardObj?.trainings
        .map(t=> {
            for (const [key, value] of Object.entries(t)) {
                return value.success > 4 ? 4 : value.success;
            }
        })
        .reduce((a, b) => a + b, 0);
        return balls/8*100;
    }

    return (
        <div className="cards">
            <ModalPopup openMod={openModal} toggleModal ={() => toggleModal(openModal, setOpenModal)} title={modalComponent?.title} widthContent={modalComponent?.widthContent}>{modalComponent?.component}</ModalPopup>
            <TrainingPanel groupID={groupID} cardsList={cardsList} customListCards={customListCards} visibleCustom={visibleCustom}/>
            <div className="cards--content">
                <div className="head" style={{flexDirection: "column"}}>
                    <div className="toggleBtn">Выбрать карточки для тренировки <input type="checkbox" onChange={(e) => setVisibleCustom(prev => !prev)} id="switch-custom" /><label for="switch-custom"></label></div>
                    {visibleCustom && (
                    <div>Выберите 4 и более карточек, подходящих для тренировки.</div>
                    )}
                </div>
                <div className="cards-list">
                    {cardsList.map((card, index) => (
                        <div className="cards--item" data-card-id={card.card_id} key={card.card_id}>
                            <div className="cards--item--table">
                                {visibleCustom && (
                                    <div><input type="checkbox" onChange={(e) => {console.log("checkbox", e.target.checked);handleCustomTraining(e.target.checked, card)}}/></div>
                                )}
                                <div className="index"><span>{index +1 + offset}</span></div>
                                <div className="img">
                                    <div className="img--content" style={{ backgroundImage: `url(${card.img})`}}></div>
                                </div>
                                <div className={"word-sound" + (isPlaying && (audioData.word == card.word) ? " play" : "")} onClick={()=> playingSound(card["sound"], card.word)}>
                                    <i className={`bx bx-volume-${card["sound"] ? "full" : "mute"}`}></i></div>
                                <div className="word"><span>{card.word}</span></div>
                                <div className="transcription"><span>{ "[" + card.transcription + "]"}</span></div>
                                <div className="translate"><span>{card.translate}</span></div>
                                <div className="progress">
                                    <div class="progress-wrap">
                                        <svg data-tooltip={`Освоено на ${countProgress(stateStatCards.cardsData?.cards?.find(c=> c.card_id == card.card_id))}%`}>
                                            <circle cx="10" cy="10" r="10"></circle>
                                            <circle cx="10" cy="10" r="10" style={{strokeDashoffset: 63 - 63 * countProgress(stateStatCards.cardsData?.cards?.find(c=> c.card_id == card.card_id)) / 100}}></circle>
                                        </svg>
                                    </div>
                                </div>
                                {((user.isAuth && ["teacher", "admin"].includes(user.userData.role))) && (
                                <div className="control-panel">
                                    <div className="edit-btn" onClick={handleEditCard}><i class='bx bx-edit'></i></div>
                                    <div className="remove-btn" onClick={handleRemoveCard}><i class='bx bx-trash'></i></div>
                                </div>
                                )}
                            </div>
                            {stateDictionary.groups.length && (
                                    <i class={`bx bxs-bookmark ${stateDictionary.cards.find(dicCard => dicCard.card_id === card.card_id) ? " dictionary" : ""}`}
                                    data-tooltip={!stateDictionary.cards.find(dicCard => dicCard.card_id === card.card_id) ? "Добавить в словарь" : "В словаре"}
                                    onClick={() => handleAddCardToDictionary(card.card_id)}></i>
                                )}
                            {card.group_ids && stateDictionary.groups.length && (
                                <div className="groups"><span>{card.group_ids.length ? 'Содержится в наборах: ' : 'Без набора'}</span>{card.group_ids.map(group => { 
                                    const findGroup = stateDictionary.groups.find(g => g.group_id === group);
                                    return <a href={`/group/${findGroup.group_id}`}>{findGroup.group_name} </a> 
                                })}</div>
                            ) }
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default CardsList;