import React, { useState, useEffect } from 'react';

export function Message({ message, remove }) {
  const [active, setActive] = useState(false);

  useEffect(() => {
    requestAnimationFrame(() => {
        setActive(true);
      });
    
    const timer = setTimeout(() => {
      setActive(false);
      setTimeout(remove, 500); 
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [remove]);

  return (
    <div className={`service-message ${message.type} ${active ? 'msg-show' : 'msg-hide'}`}>
      {message.text}
    </div>
  );
}
