import React, { useEffect, useState } from "react";

function PlayAudioCard({sound}) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [audio, setAudio] = React.useState(null);

    useEffect(() => {
        const newAudio = new Audio();
        setAudio(newAudio);
    
        const handlePlay = () => {
          setIsPlaying(true);
        };
    
        const handlePause = () => {
          setIsPlaying(false);
        };
    
        // Добавляем обработчики событий для аудио
        if (newAudio) {
          newAudio.addEventListener('play', handlePlay);
          newAudio.addEventListener('pause', handlePause);
        }
    
        return () => {
          // Удаляем обработчики событий при размонтировании компонента
          if (newAudio) {
            newAudio.removeEventListener('play', handlePlay);
            newAudio.removeEventListener('pause', handlePause);
            newAudio.pause();
          }
          setAudio(null);
        };
      }, []);

    const clickPlayPrevewAudio = () => {
        if (!sound) return;
        audio.src = sound;
        if (audio) {
            if (isPlaying) {
              audio.pause();
              setIsPlaying(false);
            } else {
              audio.play();
            }
          }
    }

    return (
        <div className={"word-sound" + (isPlaying ? " play" : "")}>
            <i className={`bx bx-volume-${sound ? "full" : "mute"}`} onClick={clickPlayPrevewAudio}></i>
        </div>
    )
}

export default PlayAudioCard