const setDictionary = (dictionary) => ({
    type: "SET_DICTIONARY",
    payload: dictionary
});

const setTempGroup = (cardsArr) => ({
    type: "SET_TEMP_GROUP",
    payload: cardsArr
});



export { setDictionary, setTempGroup };