import { configureStore } from '@reduxjs/toolkit';
  
import trainingReducer from "./reducers/training";
import groupCardsReducer from "./reducers/group-cards";
import userDataReducer from "./reducers/user-data";
import statCardsReducer from "./reducers/stat-cards";
import dictionaryReducer from './reducers/user-dictionary';

  const store = configureStore({
    reducer: {
        training: trainingReducer,
        groupCards: groupCardsReducer,
        user: userDataReducer,
        statCards: statCardsReducer,
        dictionary: dictionaryReducer
    }
  })

console.log(store.getState());
  
export default store;