import React, {useState, useSelector, useEffect} from "react";
import PlatformSettings from "../PlatformSettings.jsx";
import $api from "../../http/api";
import {useMessage} from '../../components/service-components/MessageContext.jsx';

export default function AddFrameKI({openModal, fetchKIGroups}) {
    const [integrationData, setIntegrationData] = useState({
        groupsCards: [],
        groupsKI: [],
        groupsLiteKI: [],
        kursID:[]
    });
    const [selectIntegration, setSelectIntegration] = useState({});
    const [isActivePlatformSettings, setIsActivePlatformSettings] = useState(false);

    const {addMessage} = useMessage();

    useEffect (() => {
        const objData = {
            groupsCards: [],
            groupsKI: [],
            groupsLiteKI: [],
            kursID:[]
        }
        Promise.all([
            $api.get('/getKIGroups').then(result => { // получаем список групп обучения КИ
                result.data.groups.forEach(row => {
                    objData.groupsKI.push({  //пушим список групп
                    "group_id": row.id, // id группы обучения
                    "group_name": row.name, // название группы
                    "kurs_id": row.kurs_id // курс который связан с группой
                  });
                });
                result.data.groups_lite.forEach(row => {
                    objData.groupsLiteKI.push({  //пушим список групп
                    "group_id": row.id, // id группы обучения
                    "group_name": row.name, // название группы
                    "kurs_id": row.kurs_id // курс который связан с группой
                  });
                });
              }),
              $api.post("/getGroupsCards").then((result) => {
                objData.groupsCards = result.data.groups_card})
        ])
        .then(() => {
            setIntegrationData(objData);
            // setSelectIntegration({
            //     groupCards: objData.groupsCards[0].group_id,
            //     groupKI: objData.groupsKI[0].group_id,
            //     groupLiteKI: objData.groupsLiteKI[0].group_id
            // });
        })
    }, []);

    useEffect(() => {
        console.log("integrationData", integrationData);
    }, [integrationData]);

    useEffect(() => {
        console.log("selectIntegration", selectIntegration);
    }, [selectIntegration]);

    useEffect(() => {
        setIsActivePlatformSettings(false);
        setSelectIntegration({});
    }, [openModal]);

    const handleSelectIntegration = (type, value, data) => {
        if (value === "default") {
            setSelectIntegration({...selectIntegration, [type]: ""});
        }
        else {
            setSelectIntegration({...selectIntegration, ...data});
        }
    }

    const handleAddIntegration = () => { 
        if (selectIntegration.groupCardsID && selectIntegration.groupID && selectIntegration.typeTraining && selectIntegration.kursID) {
            setIsActivePlatformSettings(true);
        }
        else {
            if (!selectIntegration.groupCardsID) addMessage({type: "error", text: "Не выбран набор карточек"});
            if (!selectIntegration.kursID) addMessage({type: "error", text: "Не выбран курс Платформы"});
            if (!selectIntegration.groupID) addMessage({type: "error", text: "Не выбрана группа, поток или тип доступа"});
            if (!selectIntegration.typeTraining) addMessage({type: "error", text: "Не выбран тип тренировки"});
        }
    }

    return (
        !isActivePlatformSettings ? (
            <>
        <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
            <label>Выберите набор карточек</label>
             <select name="groupCards" value={selectIntegration.groupCardsID} required onChange={e => handleSelectIntegration("groupCardsID", e.target.value, {groupCardsID: parseInt(e.target.value)})}>
             <option value="default" >Выбрать набор</option>
                {integrationData.groupsCards.map(group => (
                <option key={group.group_id} selected={selectIntegration.groupCards == group.group_id} value={group.group_id}>{group.group_name}</option>
                ))}
            </select>
        </div>
        <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
            <label>Выберите тип тренировки</label>
             <select name="typeTraining" required onChange={e => handleSelectIntegration("typeTraining", e.target.value, {typeTraining: e.target.value})}>
             <option value="default">Выбрать тренировку</option>
             <option value="word-translation">Иврит-Русский</option>
             <option value="translation-word">Русский-Иврит</option>
             <option value="image-word">Картинка-Иврит</option>
            </select>
        </div><div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
            <label>Выберите курс на платформе</label>
             <select name="kursID" required onChange={e => handleSelectIntegration("kursID", e.target.value, {kursID: parseInt(e.target.value)})}>
                <option value="default">Выбрать курс обучения</option>
                <option value="25">25 - Алеф</option>
                <option value="26">26 - Бет</option>
            </select>
        </div>
        <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
            <label>Выберите группу, поток или тип доступа</label>
            <div style={{display: "flex", flexDirection: "row", gap: "10px", flexWrap: "wrap"}}>
            <select name="groupKI" onChange={e => handleSelectIntegration("groupID", e.target.value, {type: "group", groupID: parseInt(e.target.value)})}>
                <option selected={!selectIntegration.type || selectIntegration.type == "group_lite" || selectIntegration.type =="special"} value="default" >Выбрать группу</option>
                {integrationData.groupsKI.map(group => (
                <option key={group.group_id} value={group.group_id}>{group.group_name}</option>
                ))}
            </select>
            <select name="group_liteKI" onChange={e => handleSelectIntegration("groupID", e.target.value, {type: "group_lite", groupID: parseInt(e.target.value)})}>
                <option selected={!selectIntegration.type || selectIntegration.type == "group" || selectIntegration.type =="special"} value="default" >Выбрать поток</option>
                {integrationData.groupsLiteKI.map(group => (
                <option key={group.group_id} value={group.group_id}>{group.group_name}</option>
                ))}
            </select>
            <select name="special" onChange={e => handleSelectIntegration("special", e.target.value, {type: "special", groupID: e.target.value})}>
                <option selected={!selectIntegration.type || selectIntegration.type == "group" || selectIntegration.type == "group_lite"} value="default" >Выбрать доступ</option>
                <option value="kk">Клубная карта</option>
            </select>
            </div>
        </div>
        <button onClick={() => handleAddIntegration()}>Добавить</button>
        </>
        ) : (
            <PlatformSettings integration={selectIntegration} fetchKIGroups={fetchKIGroups} />
        )
    )
}