import React, {createContext, useContext, useState, useCallback} from "react";
import {Message} from "./Message.jsx";

const MessageContext = createContext(null);

export const MessageProvider = ({children}) => {

    const [messages, setMessage] = useState([]);

    const addMessage = useCallback((message) => {
        const id = Date.now();
        setMessage(messages => [...messages, {id, message}]);
    }, []);

    const removeMessage = useCallback((id) => {
        setMessage(messages => {
            return messages.filter((message) => message.id !== id);
        })
    }, []);

    return (
        <MessageContext.Provider value={{addMessage, removeMessage}}>
            {children}
            <div className="service-messages--contaner">
                {messages.map((item) => {
                    return (
                        <Message key={item.id} message={item.message} remove={() => removeMessage(item.id)} />
                    )
                })}
            </div>
        </MessageContext.Provider>
    )
};

export const useMessage = () => {
    const context = useContext(MessageContext);
    if (!context) {
        throw new Error('Ошибка: вызов useMessage вне MessageProvider');
    }
    return context;
};
