import StatisticService from "../../services/statistic-service";

const statCardsInitialState = {
    cardsData: {},
    isLoaded: false
}

const statCards = (state = statCardsInitialState, action) => {
    switch(action.type) {
        case "UPDATE_TRAINING_CARD":
            const {card, typeTraining, isCorrectAnswer} = action.payload;
            // Создаем полную копию cardsData, чтобы избежать мутаций
            let newCardsData = {...state.cardsData};

            // Убеждаемся, что card и typeTraining также копируются правильно
            if (!newCardsData[card.card_id]) {
                newCardsData[card.card_id] = {};
            } else {
                // Копируем объект card, чтобы избежать прямой мутации
                newCardsData[card.card_id] = {...newCardsData[card.card_id]};
            }

            // Проверяем и обновляем счетчик
            if (isCorrectAnswer) {
                newCardsData[card.card_id][typeTraining] = (newCardsData[card.card_id][typeTraining] || 0) + 1;
            } else {
                if (newCardsData[card.card_id][typeTraining] > 0) {
                    newCardsData[card.card_id][typeTraining] -= 1;
                } else {
                    newCardsData[card.card_id][typeTraining] = 0;
                }
            }
            //StatisticService.setStatCards(newCardsData);

            return {
                ...state,
                cardsData: newCardsData
            };
        case "SET_STAT_CARDS": 
            return {
                ...state,
                cardsData: action.payload,
                isLoaded: true
            }
        default:
            return state;
    }
};






export default statCards;