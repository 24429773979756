
const trainingInitialState = {
    cards:{},
    typeTraining: null,
    isLoaded:false,
    currentCard: 0,
    nextCard: 1,
    dataAnswer:{},
}

const training = (state = trainingInitialState, action) => {
    switch(action.type) {
        case "SET_CARDS":
            return {
                ...state,
                cards: action.cards,
                isLoaded: true
            }
        default: return state;
    }
};

export default training;