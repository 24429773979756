import React, { useEffect, useState } from "react";
import EditCardForm from "./EditCardForm.jsx";
import { useSelector, useDispatch } from 'react-redux';
import { setUserStatCards } from '../redux/actions/stat-cards.js';
import $api from '../http/api.js';
import { setDictionary } from '../redux/actions/dictionary.js';
import {useMessage} from '../components/service-components/MessageContext.jsx';

function DeletedCardsList({cardsList, btnDictionary}) {
    console.log("DeletedCardsList", cardsList);

    const [isPlaying, setIsPlaying] = useState(false);
    const [audio, setAudio] = useState(null);
    const [audioData, setAudioData] = useState(null);

    const dispatch = useDispatch();

    const user = useSelector(state => state.user);

    const stateDictionary = useSelector((state) => state.dictionary);
    const stateStatCards = useSelector(state => state.statCards);
    const [showDeleted, setShowDeleted] = useState(false);

    const {addMessage} = useMessage();

    useEffect(() => {
        if (!stateStatCards.isLoaded) {
            $api.post('/user/getUserStatCards').then((result) => {
                dispatch(setUserStatCards(result.data.cardsStat));
            });
        };

        const newAudio = new Audio();
        setAudio(newAudio);
    
        const handlePlay = () => {
            setIsPlaying(true);
        };
    
        const handlePause = () => {
          setIsPlaying(false);
        };
    
        // Добавляем обработчики событий для аудио
        if (newAudio) {
          newAudio.addEventListener('play', handlePlay);
          newAudio.addEventListener('pause', handlePause);
        }
    
        return () => {
          // Удаляем обработчики событий при размонтировании компонента
          if (newAudio) {
            newAudio.removeEventListener('play', handlePlay);
            newAudio.removeEventListener('pause', handlePause);
            newAudio.pause();
          }
          setAudio(null);
        };
      }, []);

    function handleRestoreCard(e) {
        const card = e.target.closest(".cards--item");
        const cardID = card.dataset.cardId;
        $api.post('/restoreCardInDictionary', {card_id: cardID})
            .then(result => {
                if (result.status == 200) {
                    addMessage({type: "success", text: "Карточка активирована"});
                    dispatch(setDictionary(result.data));
                }
            })
            .catch(error => {
                const text = error.response?.data?.message ? error.response.data.message : error.message;
                addMessage({type: "error", text: text});
            })
    }

    function playingSound(srcAudio, word) {
        audio.src = srcAudio;
        if (audio) {
            if (isPlaying) {
              audio.pause();
              setIsPlaying(false);
            } else {
                setAudioData({word: word});
                audio.play();
            }
          }
    };

    function countProgress(cardObj){
        if (!cardObj) return 0;
        const balls=cardObj?.trainings
        .map(t=> {
            for (const [key, value] of Object.entries(t)) {
                return value.success > 4 ? 4 : value.success;
            }
        })
        .reduce((a, b) => a + b, 0);
        return balls/8*100;
    }

    return (
        <div className="cards">
            <div className="cards--content">
                <div className="head">
                    <div className="title" style={{padding: "15px 0px"}}>В этом наборе присутствуют карточки, которые вы ранее удаляли из своего словаря.<br/>
                    Удаленные карточки не участвуют в тренировках.<br/>
                    Вы можете снова их активировать.</div>
                    <div className="toggleBtn">Показать удаленные карточки<input type="checkbox" onChange={(e) => setShowDeleted(e.target.checked)} id="switch-deleted" /><label for="switch-deleted"></label></div>
                </div>
                <div className="cards-list deleted-cards">
                    {showDeleted &&cardsList.map((card, index) => (
                        <div className="cards--item" data-card-id={card.card_id} key={card.card_id}>
                            <div className="cards--item--table">
                                <div className="index"><span>{index +1}</span></div>
                                <div className="img">
                                    <div className="img--content" style={{ backgroundImage: `url(${card.img})`}}></div>
                                </div>
                                <div className={"word-sound" + (isPlaying && (audioData.word == card.word) ? " play" : "")} onClick={()=> playingSound(card["sound"], card.word)}>
                                    <i className={`bx bx-volume-${card["sound"] ? "full" : "mute"}`}></i></div>
                                <div className="word"><span>{card.word}</span></div>
                                <div className="transcription"><span>{ "[" + card.transcription + "]"}</span></div>
                                <div className="translate"><span>{card.translate}</span></div>
                                <div className="progress">
                                    <div class="progress-wrap">
                                        <svg data-tooltip={`Освоено на ${countProgress(stateStatCards.cardsData?.cards?.find(c=> c.card_id == card.card_id))}%`}>
                                            <circle cx="10" cy="10" r="10"></circle>
                                            <circle cx="10" cy="10" r="10" style={{strokeDashoffset: 63 - 63 * countProgress(stateStatCards.cardsData?.cards?.find(c=> c.card_id == card.card_id)) / 100}}></circle>
                                        </svg>
                                    </div>
                                </div>
                                {((user.isAuth && ["teacher", "admin"].includes(user.userData.role))) && (
                                <div className="control-panel">
                                    <div className="dictionary-btn" onClick={handleRestoreCard}><i class='bx bx-revision bx-flip-horizontal' data-tooltip="Активировать"></i></div>
                                </div>
                                )}
                            </div>
                            {card.group_ids && stateDictionary.groups.length && (
                                <div className="groups"><span>{card.group_ids.length ? 'Содержится в наборах: ' : 'Без набора'}</span>{card.group_ids.map(group => { 
                                    const findGroup = stateDictionary.groups.find(g => g.group_id === group);
                                    return <a href={`/group/${findGroup.group_id}`}>{findGroup.group_name} </a> 
                                })}</div>
                            ) }
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default DeletedCardsList;