const userLogin = (userData) => ({
    type: "USER_LOGIN",
    payload: userData
});

const userLogout = (userData) => ({
    type: "USER_LOGOUT"
});

const userLoad = () => ({
    type: "USER_LOAD"
});

export { userLogin, userLogout, userLoad };