import React from "react";
import {GroupsCard, AddCardForm, AddGroupForm} from "../components/";
import ModalPopup, {toggleModal} from "../components/ModalPopup.jsx";
import CheckUserRole from "../components/service-components/CheckUserRole.jsx";
import { useSelector } from "react-redux";

function Library() {
    const[openModal, setOpenModal] = React.useState(false);
    const user = useSelector(state => state.user);
    return (
        <div className="library">
            <div className="library--wrapper">
                <div className="library--content">
                <ModalPopup openMod={openModal} toggleModal ={() => toggleModal(openModal, setOpenModal)} title='Добавить набор'><AddGroupForm /></ModalPopup>
                    <div className="head">
                        <div className="title"><span>Наборы карточек</span></div>
                        {(user.isAuth && ["teacher", "admin"].includes(user.userData.role)) && (
                                <div className="btn blue-btn" onClick={() => toggleModal(openModal, setOpenModal)}>Добавить набор</div>
                        )}
                    </div>
                    <GroupsCard />
                </div>
            </div>
        </div>
    );
}

export default CheckUserRole(Library, { isPage: true }, ["teacher","admin"]);