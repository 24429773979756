
const groupCardsInitialState = {
    groups: [],
    isLoaded:false,
}

const groupCards = (state = groupCardsInitialState, action) => {
    switch(action.type) {
        case "SET_GROUPS_CARDS":
            return {
                ...state,
                groups: action.payload,
                isLoaded: true
            }
        case "UPDATE_GROUP_INFO":
            console.log("updateGroupInfo", action);
            if (state.groups.length > 0) {
                return {
                    ...state,
                    groups: state.groups.map((group) => {
                        if (group.group_id === action.payload.group_id) {
                            return action.payload
                        }
                        return group;
                    }),
                    isLoaded: true
                }
            }
            else {
                return {
                    ...state,
                    groups: [action.payload],
                }
            }
        case "ADD_NEW_GROUP":
            console.log("addNewGroup", action);
            const newGrops = state.groups;
            newGrops.push(action.payload);
            return {
                ...state,
                groups: newGrops,
                isLoaded: true
            }
        default: return state;
    }
};

export default groupCards;