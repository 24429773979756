import React, {useState, useEffect} from "react";
import {GroupsCard, CardsList} from "../components/";
import CheckUserRole from "../components/service-components/CheckUserRole.jsx";
import { useSelector, useDispatch } from 'react-redux';
import $api from '../http/api';
import { setDictionary } from '../redux/actions/dictionary';

function Dictionary() {

    const dispatch = useDispatch();
    const stateDictionary = useSelector((state) => state.dictionary);

    const [event, setEvent] = useState(null);

    const updateDictionary = () => {
        $api.get("/getUserDictionary").then((result) => {
            dispatch(setDictionary(result.data));
        });
    }

/*     useEffect(() => {
        if (!stateDictionary.isLoaded) {
            updateDictionary();
        }
    }, []); */

    useEffect(() => {
        if (event) {
            updateDictionary();
        }
    }, [event]);



    return (
        <div className="dictionary">
            <div className="dictionary--wrapper">
                <div className="dictionary--content">
                    <div className="head">
                        <div className="title"><span>Наборы карточек</span></div>
                    </div>
                    <GroupsCard data={stateDictionary.groups} page={"dictionary"}/>
                    <div className="head">
                        <div className="title"><span>Словарь карточек</span></div>
                    </div>
                    <CardsList cardsList={stateDictionary.cards} setEvent={setEvent}/>
                </div>
            </div>
        </div>
    );
}

export default CheckUserRole(Dictionary, { isPage: true }, ["teacher", "admin"]);