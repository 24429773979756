import React, { useEffect, useState } from "react";
import $api from "../http/api";
import {debounce} from '../services/functions.js';


function MiniSearchCards({searchCard, searched, setSearched, choiseSearchCard, setChoiseSearchCard, setFormData, setIsSearchCard, setPreviewUrl}) {

    const searchCards = (word) => {
        if (!word) return setSearched(prev => ({...prev, isVisible: false, cards: []}));
        $api.post(`/searchCards/`, {target: word})
          .then((response) => {
            console.log('Найдено:', response.data);
            setSearched(prev => ({...prev, cards: response.data.cards}));
          })
          .catch((error) => {
            console.error('Ошибка при отправке данных:', error);
          });
      }

    useEffect(() => {
        if (searchCard.length > 0) {
            const searchCardDebounce = debounce(searchCards, 500);
            searchCardDebounce(searchCard);
        }
        else setSearched(prev => ({...prev, isVisible: false, cards: []}));
    }, [searchCard]);

    return (
        <>
        {searched.isVisible && searched.cards.length && (
            <div className="searched--container" tabIndex={ 0 } ref={elem => elem && elem.focus()} onBlur={(e)=> {!e.currentTarget.contains(e.relatedTarget) && setSearched(prev => ({...prev, isVisible: !prev.isVisible}))}}>
              {searched.cards.map(card => (
                <div className="item" key={card.card_id} data-card={card.card_id}>
  
                  <div style={{position: 'relative'}}>
                    <img src={card.img} style={{width: "50px"}}/>
                  </div>
                  <div>{card.word}</div>
                  <i className={`bx sound bx-volume-${card["sound"] ? "full" : "mute"}`}></i>
                  <div style={{flex:1}}>{card.translate}</div>
                  <i class={'bx ' + (choiseSearchCard === card.card_id ? 'bxs-check-circle' : 'bx-circle')} onClick={()=> {setChoiseSearchCard(card.card_id);setFormData((prevData) => ({...prevData, ...card})); setIsSearchCard(true); setPreviewUrl(card.img)}}></i>
                </div>
              ))}
            </div>
          )}
          {searched.cards.length ? 
            <div>
              <div style={{fontSize: "14px"}}>{`Найдено ${searched.cards.length} карточки со словом ${searchCard}`}</div>
                <div style={{display:"flex", gap: "10px"}}>
                <div style={{fontSize: "14px"}} className="btn dark-blue" onClick={() => setSearched(prev => ({...prev, isVisible: true}))}>Выбрать...</div><div style={{fontSize: "14px"}} className="btn dark-blue" onClick={()=>{setSearched((prev => ({...prev, isVisible: false, cards: []}))); setChoiseSearchCard(null) ; setIsSearchCard(false)}}>Создать новую</div>
              </div>
            </div>
        : null}
        </>
    )
}

export default MiniSearchCards