import React, {useState, useEffect, useRef} from "react";
function PreviewImage({previewUrl, formData, bounds, setBounds, isSearchCard, choiseSearchCard}) {
    const [scale, setScale] = useState(1);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [dragging, setDragging] = useState(false);
    const imageRef = useRef(null);
    const wrapperRef = useRef(null);

    const startDrag = (e) => {
        e.preventDefault();
        setDragging(true);
      };
    
      const onDrag = (e) => {
        if (!dragging) return;
        if (!bounds) {
            setBounds(getBounds());
        }
        const deltaX = e.movementX;
        const deltaY = e.movementY;
      
        let withinHorizontalBounds, withinVerticalBounds;
        const newX = position.x + deltaX;
        /* if (bounds.image.width <= bounds.wrapper.width) {
          withinHorizontalBounds = bounds.image.left + deltaX > bounds.wrapper.left && bounds.image.right + deltaX < bounds.wrapper.right;
        }
        else {
          withinHorizontalBounds = bounds.image.left + deltaX < bounds.wrapper.left && bounds.image.right + deltaX > bounds.wrapper.right;
        } */
        // const x = withinHorizontalBounds ? newX : position.x;
        const x = newX;
  
      
        const newY = position.y + deltaY;
         /* if (bounds.image.top > bounds.wrapper.top && bounds.image.bottom < bounds.wrapper.bottom) {
          withinVerticalBounds = bounds.image.top + deltaY > bounds.wrapper.top && bounds.image.bottom + deltaY < bounds.wrapper.bottom;
        }
        else {
          if (bounds.image.top + deltaY > bounds.image.top) {
            withinVerticalBounds = bounds.image.top + deltaY < bounds.wrapper.top;
          }
          else {
            withinVerticalBounds = bounds.image.bottom + deltaY > bounds.wrapper.bottom;
          } 
        } */
        // const y = withinVerticalBounds ? newY : position.y;
        const y = newY;
        setPosition({ x, y });
      };
    
      const stopDrag = () => {
        setDragging(false);
      };
    
      const handleWheel = (e) => {
        e.preventDefault();
        const direction = e.deltaY < 0 ? 1 : -1;
        const newScale = scale + direction * 0.1;
        setScale(Math.min(Math.max(newScale, 0.5), 4));
      };
    
      function getBounds() {
        if (!previewUrl) return;
        const wrapper = wrapperRef?.current;
        const image = imageRef?.current;
        console.log("image", image);
        console.log("image height", image.height);
    
        if (wrapper && image) {
          const wrapperBounds = wrapper.getBoundingClientRect();
          const imageBounds = image.getBoundingClientRect();
          console.log("imageBounds", imageBounds);

          wrapperBounds.centerX = wrapperBounds.width/2 + wrapperBounds.left;
          wrapperBounds.centerY = wrapperBounds.height/2 + wrapperBounds.top;

          imageBounds.centerX = imageBounds.width/2 + imageBounds.left;
          imageBounds.centerY = imageBounds.height/2 + imageBounds.top;

          imageBounds.dcx = imageBounds.centerX - wrapperBounds.centerX;
          imageBounds.dcy = imageBounds.centerY - wrapperBounds.centerY;
    
          return {
            wrapper: wrapperBounds,
            image: imageBounds,
          };
        } else {
          return null;
        }
      }
    
       useEffect(() => {
        const imageElement = imageRef.current;
        if (!imageElement || choiseSearchCard) return;
        if (imageElement) {
          const handleLoad = () => {
            setBounds(getBounds());
            }
          if (imageElement.complete) {
            handleLoad();
          } else {
            imageElement.addEventListener('load', handleLoad);
          }
          return () => {
            imageElement.removeEventListener('load', handleLoad);
          };
        };
       }, [position, scale]);
    
      useEffect(() => {
        const imageElement = imageRef.current;
        if (!imageElement) return;
        imageElement.addEventListener('wheel', handleWheel, { passive: false });
    
        return () => {
          if (imageElement) {
            imageElement.removeEventListener('wheel', handleWheel);
          }
        };
      }, [scale]);

      React.useEffect(() => {
        const imageElement = imageRef.current;
        if (!imageElement || choiseSearchCard) return;
        if (imageElement) {
          const handleLoad = () => {
            setBounds(getBounds());
            }
          if (imageElement.complete) {
            handleLoad();
          } else {
            imageElement.addEventListener('load', handleLoad);
          }
            setScale(1);
            setPosition({ x: 0, y: 0 });
            console.log("imageElement load", imageElement);
            updatePosition();
            imageElement.addEventListener('wheel', handleWheel, { passive: false });
            return () => {
              imageElement.removeEventListener('load', handleLoad);
            };
        }
      }, [previewUrl]);

      const updatePosition = () => {
        console.log("start updatePosition", previewUrl);
        if (!previewUrl) {
          return;
        }
        const imageElement = imageRef?.current;
        console.log("updatePosition imageElement", imageElement);
        if (!imageElement) return;
        const wrapper = wrapperRef?.current;
        const wrapperBounds = wrapper.getBoundingClientRect();
        console.log("updatePosition wrapperBounds", wrapperBounds);
        const imgDCX = formData.img_data?.img_dcx ?? 0;
        const imgDCY = formData.img_data?.img_dcy ?? 0;
        const imgWidth = formData.img_data?.img_w ?? wrapperBounds.width;
        const imgHeight = formData.img_data?.img_h ?? wrapperBounds.height;
        const wrapperCenterX = wrapperBounds.width/2 + wrapperBounds.left;
        const wrapperCenterY = wrapperBounds.height/2 + wrapperBounds.top;
        const imageCenterX = imgDCX + wrapperCenterX;
        const imageCenterY = imgDCY + wrapperCenterY;
        const imageX = (imageCenterX - imgWidth/2) - wrapperBounds.left;
        const imageY = (imageCenterY - imgHeight/2) - wrapperBounds.top;
        console.log("updatePosition imageX", imageX, "imageY", imageY);
        setPosition({ x: imageX, y: imageY });
        imageRef.current.style.width = formData.img_data?.img_w ? formData.img_data.img_w + "px" : wrapperBounds.width;
      }

      React.useEffect(() => {
        if (choiseSearchCard === false) {
          return;
        }
        updatePosition();
      }, [choiseSearchCard]);


    return (
        <div className="card-img--wrapper">
      <div className="card-img--content" ref={wrapperRef}>
        {previewUrl && (
          <img
            alt="preview"
            ref={imageRef}
            src={previewUrl}
            style={{
              width: "100%",
              objectFit: "cover",
              top: position.y + "px",
              left: position.x + "px",
              position: "relative",
              transform: `scale(${scale})`,
              transformOrigin: 'center center'
            }}
            onMouseDown={!isSearchCard ? startDrag : null}
            onMouseMove={!isSearchCard ? onDrag : null}
            onMouseUp={!isSearchCard ? stopDrag : null}
            onMouseLeave={!isSearchCard ? stopDrag : null}
            draggable={false}
          />
        )}
      </div>
    </div>
    )
}
export default PreviewImage