import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";

const rules = {
    "learning": {
        url: "/training/learning/",
        requiredData: ["img", "sound", "trascription"],
        typeCard: ["symbol", "word", "phrase"]
    },
    "word-translation": {
        url: "/training/word-translation/",
        requiredData: [],
        typeCard: ["symbol", "word", "phrase"]
    },
    "translation-word": {
        url: "/training/translation-word/",
        requiredData: [],
        typeCard: ["symbol", "word", "phrase"]
    },
    "image-word": {
        url: "/training/image-word/",
        requiredData: ["img"],
        typeCard: ["symbol", "word", "phrase"]
    },
    "writing": {
        url: "/training/writing/",
        requiredData: [],
        typeCard: ["word", "phrase"]
    }
}

export function PrepareTrainingLink({to, groupID, cardsList, customListCards, visibleCustom, children}) {
    const currentList = visibleCustom ? customListCards : cardsList;
    const currentType = groupID ? 
    (visibleCustom ? 'custom' : groupID) 
    : (visibleCustom ? 'custom' : 'dictionary');

    let countRightCards = 0;

    for (const card of currentList) {
        if (countRightCards >=4) {
            break;
        }
        if (rules[to].requiredData.every(item => card[item] !== '') && rules[to].typeCard.includes(card.type)) {
            countRightCards++;
        }
    }

    if (countRightCards < 4) {
        return <><span data-tooltip="Недостаточно карточек для тренировки">{children}</span></>;
    }
    else {
        return <Link to={rules[to].url + currentType}><span className="link-active">{children}</span></Link>;
    }

}