import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $api from "../../http/api";
import { useSelector } from 'react-redux';
import PlatformSettings from "../PlatformSettings.jsx";
import { AddFrameKI } from "../admin";
import ModalPopup, { toggleModal } from "../ModalPopup.jsx";

export default function PlatformGroups() {
    const user = useSelector(state => state.user);

    const [KIGroupsData, setKIGroupsData] = useState([]);
    const [openModal, setOpenModal] = React.useState(false);
    const [modalComponent, setModalComponent] = React.useState(null);
    const [sortTable, setSortTable] = useState({ column: 'id', direction: 'asc' });
    const [activeTab, setActiveTab] = useState("my");
    const [integrationList, setIntegrationList] = useState([]);

    useEffect(() => {
        fetchKIGroups();
    }, []);

    async function fetchKIGroups() {
        try {
            const response = await $api.get(`/getKIFrames`);
            setKIGroupsData(response.data);
            setIntegrationList(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    function handleSortTable(column) {
        setSortTable(prevSortTable => ({
            column,
            direction: prevSortTable.column === column && prevSortTable.direction === 'asc' ? 'desc' : 'asc'
        }));
    }

    function handleClickTab(name) {
        if (!KIGroupsData || !KIGroupsData.frames) {
            return;
        }
        setActiveTab(name);
        let filteredList;
        if (name === "my") {
            filteredList = KIGroupsData.frames.filter(row => parseInt(row.author_id) === user.userData.id);
        } else {
            filteredList = KIGroupsData.frames;
        }
        setIntegrationList({ ...integrationList, frames: filteredList });
    }

    useEffect(() => {
        if (integrationList.frames) {
            sortTableByColumn();
        }
    }, [sortTable, activeTab]);

    function sortTableByColumn() {
        const sorted = [...integrationList.frames].sort((a, b) => {
            const column = sortTable.column;
            const direction = sortTable.direction === 'asc' ? 1 : -1;

            if (typeof a[column] === 'number' && typeof b[column] === 'number') {
                return direction * (a[column] - b[column]);
            } else if (typeof a[column] === 'string' && typeof b[column] === 'string') {
                return direction * a[column].localeCompare(b[column]);
            } else {
                return direction * (a[column].toString()).localeCompare(b[column].toString());
            }
        });
        setIntegrationList(prevState => ({ ...prevState, frames: sorted }));
    }

    useEffect(() => {
        if (KIGroupsData.frames) {
            handleClickTab(activeTab);
        }
    }, [KIGroupsData]);

    return (
        <div className="content--wrapper">
            <ModalPopup openMod={openModal} toggleModal={() => toggleModal(openModal, setOpenModal)} title={modalComponent?.title}>
                {modalComponent?.component}
            </ModalPopup>
            <div className="head">
                <div className="title"><span>Настройка интеграций</span></div>
                {user.isAuth && (
                    <div className="btn blue-btn" onClick={() => {
                        setModalComponent({ component: <AddFrameKI fetchKIGroups={fetchKIGroups} />, title: 'Добавить интеграцию' });
                        toggleModal(openModal, setOpenModal);
                    }}>Добавить интеграцию</div>
                )}
            </div>
            <div className="table">
                <div className="panel"></div>
                <div className="tabs">
                    <div className={`tab ${activeTab === 'my' ? 'active' : ''}`} onClick={() => handleClickTab('my')}>Мои интеграции</div>
                    <div className={`tab ${activeTab === 'all' ? 'active' : ''}`} onClick={() => handleClickTab('all')}>Все интеграции</div>
                </div>
                <div className="list-head">
                    <div className={`num ${sortTable.column === 'id' ? sortTable.direction : ''}`} onClick={() => handleSortTable('id')} style={{ width: "5%" }}>#</div>
                    <div className={`groupCards ${sortTable.column === 'quizlet_group_card' ? sortTable.direction : ''}`} onClick={() => handleSortTable('quizlet_group_card')} style={{ width: "20%" }}>Набор</div>
                    <div className={`groupKI ${sortTable.column === 'group_name' ? sortTable.direction : ''}`} onClick={() => handleSortTable('group_name')} style={{ width: "30%" }}>Группа</div>
                    <div className={`themesKI ${sortTable.column === 'lessons' ? sortTable.direction : ''}`} onClick={() => handleSortTable('lessons')} style={{ width: "45%" }}>Темы</div>
                    <div className={`typeTraining ${sortTable.column === 'type_training' ? sortTable.direction : ''}`} onClick={() => handleSortTable('type_training')} style={{ width: "15%" }}>Тренировка</div>
                    <div style={{ width: "5%" }}></div>
                </div>
                <div className="platform-groups--list">
                    {integrationList?.frames?.map((frame, index) => (
                        <div key={frame.id} className="platform-group--item">
                            <div className="table">
                                <div className="id">{index + 1}</div>
                                <div className="name" style={{ width: "20%", textDecoration: "underline" }}>
                                    <Link to={`/group/${frame.quizlet_group_card}`}>{KIGroupsData?.groupsList[parseInt(frame.quizlet_group_card)]?.group_name}</Link>
                                </div>
                                <div className="ki-group" style={{ width: "30%" }}>{frame.group_name}</div>
                                <div className="ki-lessons" style={{ width: "45%" }}>
                                    {JSON.parse(frame.lessons).map(lesson => {
                                        return KIGroupsData.themesData.find(theme => theme.theme_id == lesson).theme_name;
                                    }).join(" | ")}
                                </div>
                                <div className="training" style={{ width: "15%" }}>{frame.type_training}</div>
                                <div style={{ width: "5%" }} onClick={() => {
                                    setModalComponent({
                                        component: <PlatformSettings
                                            integration={{
                                                groupCardsID: parseInt(frame.quizlet_group_card),
                                                type: frame.group_type,
                                                groupID: parseInt(frame.group_id),
                                                kursID: parseInt(frame.kurs_id),
                                                typeTraining: frame.type_training
                                            }}
                                            fetchKIGroups={fetchKIGroups} />,
                                        title: 'Настройка интеграции'
                                    });
                                    toggleModal(openModal, setOpenModal)
                                }} className="edit-btn"><i className="bx bx-edit"></i></div>
                            </div>
                            <div className="info">
                                <span>{`Создано: ${frame.author_email} | ${frame.created_at} | ${frame.group_type}`}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
