exports.debounce = function (func, wait) {
    return function (...args) {
        let prevCall = this.lastCall || 0;
        this.lastCall = new Date().getTime();
        let diff = this.lastCall - prevCall;
        if (diff <= wait) {
            clearTimeout(this.lastCallTimer);
        }
        this.lastCallTimer = setTimeout(() => func(...args), wait);
    }
}