import React, {useState, useRef} from "react";

function BlockTrainingWriting({cardsData, type, answers, isUserChoiceMade, settings, setActiveAnswer}) {
    const wordType = "translate";
    const wordAnswer = "word";
    const [cardClasses, setCardClasses] = React.useState({
        firstCardClass: "currentCard",
        secondCardClass: "nextCard"
    });

    const [width, setWidth] = useState(window.innerWidth);
    const firstCardClass = cardClasses.firstCardClass;
    const secondCardClass = cardClasses.secondCardClass;
    const [isAnimate, setAnimate] = React.useState(false);
    const [cards, setCard] = React.useState({
        currentCard: cardsData[0],
        nextCard: cardsData[1]
    });

    const wrapperRef = useRef(null);
    const imageRef = useRef(null);
    const imageRef2 = useRef(null);
    const [shakedWord, setShakedWord] = React.useState(() => genShakedWord(cardsData[0][wordAnswer]));
    const shakedContainer = useRef();
    const [writedWord, setWritedWord] = React.useState(new Map());
    const [correctAnswer, setCorrectAnswer] = React.useState(null);
    const [isChecked, setIsChecked] = React.useState(false);
    const [hide, setHide] = React.useState('');
    const [isPlaying, setIsPlaying] = React.useState(false);
    const [audio, setAudio] = React.useState(null);
    const [currentAudioPromise, setCurrentAudioPromise] = useState(null);
    const [audioPromise, setAudioPromise] = useState(null);
    const [audioResolve, setAudioResolve] = useState(null);

    const prevDataRef = useRef();

    const currentCard = cards.currentCard;
    const nextCard = cards.nextCard;

    React.useEffect(() => {
        setCorrectAnswer(null);
        setIsChecked(false);
    }, [cardsData]);

    React.useEffect(()=> {
        setShakedWord(genShakedWord(currentCard[wordAnswer]));
        setWritedWord(new Map());
    }, [currentCard]);

    React.useEffect(()=> {
        console.log("shakedWord", shakedWord);
        console.log("splitWidthNikud", splitWithNikud(currentCard[wordAnswer]).reverse());
    }, [shakedWord]);

    React.useEffect(() => {
        if (prevDataRef.current !== undefined && prevDataRef.current !== cardsData) {
            console.log('cardsData changed from', prevDataRef.current, 'to', cardsData);
          }
          prevDataRef.current = cardsData;
    }, [cardsData]);

    React.useEffect(() => {
        const imageElement = imageRef.current;
        if (!imageElement) return;
            const wrapper = wrapperRef?.current;
            const wrapperBounds = wrapper.getBoundingClientRect();
            const wrapperCenterX = wrapperBounds.width/2 + wrapperBounds.left;
            const wrapperCenterY = wrapperBounds.height/2 + wrapperBounds.top;
            if (currentCard && imageRef) {
                let imageCenterX, imageCenterY, imageX, imageY;
                imageCenterX = currentCard.img_data?.img_dcx + wrapperCenterX;
                imageCenterY = currentCard.img_data?.img_dcy + wrapperCenterY;
                imageX = (imageCenterX - currentCard.img_data?.img_w/2) - wrapperBounds.left;
                imageY = (imageCenterY - currentCard.img_data?.img_h/2) - wrapperBounds.top;
                imageRef.current.style.top = imageY + "px";
                imageRef.current.style.left = imageX + "px";
                imageRef.current.style.height = currentCard.img_data?.img_h + "px";
                imageRef.current.style.width = currentCard.img_data?.img_w + "px";
            }

            if(nextCard && imageRef2) {
                let imageCenterX, imageCenterY, imageX, imageY;
                console.log("nextCard", nextCard);
                imageCenterX = nextCard.img_data?.img_dcx + wrapperCenterX;
                console.log("imageCenterX", imageCenterX);
                console.log("img_dcx: " + nextCard.img_data?.img_dcx + " + wrapperCenterX: " + wrapperCenterX + " = " + (nextCard.img_data?.img_dcx + wrapperCenterX));
                imageCenterX = nextCard.img_data?.img_dcx + wrapperCenterX;
                console.log("imageCenterX", imageCenterX);
                imageCenterY = nextCard.img_data?.img_dcy + wrapperCenterY;
                imageX = (imageCenterX - nextCard.img_data?.img_w/2) - wrapperBounds.left;
                imageY = (imageCenterY - nextCard.img_data?.img_h/2) - wrapperBounds.top;
                imageRef2.current.style.top = imageY + "px";
                console.log("imageX", (imageCenterX - nextCard.img_data?.img_w/2) - wrapperBounds.left);
                imageRef2.current.style.left = imageX + "px";
                imageRef2.current.style.height = nextCard.img_data?.img_h + "px";
                imageRef2.current.style.width = nextCard.img_data?.img_w + "px";
                console.log({imageX:imageX, imageY:imageY, wrapperCenterX:wrapperCenterX, wrapperCenterY:wrapperCenterY, imageCenterX: imageCenterX, imageCenterX: imageCenterY, wrapperBounds: wrapperBounds, img_data: nextCard.img_data});
            }

            // console.log({imageX:imageX, imageY:imageY, wrapperCenterX:wrapperCenterX, wrapperCenterY:wrapperCenterY, imageCenterX: imageCenterX, imageCenterX: imageCenterY, wrapperBounds: wrapperBounds, img_data: nextCard.img_data});
      }, [currentCard, nextCard, width]);

      React.useEffect(() => {
        const handleResize = (event) => {
          setWidth(event.target.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);


    React.useEffect(() => {
        const newAudio = new Audio();
        setAudio(newAudio);
    
        const handlePlay = () => {
          setIsPlaying(true);
        };
    
        const handlePause = () => {
          setIsPlaying(false);
        };
    
        // Добавляем обработчики событий для аудио
        if (newAudio) {
          newAudio.addEventListener('play', handlePlay);
          newAudio.addEventListener('pause', handlePause);
        }
    
        return () => {
          // Удаляем обработчики событий при размонтировании компонента
          if (newAudio) {
            newAudio.removeEventListener('play', handlePlay);
            newAudio.removeEventListener('pause', handlePause);
            newAudio.pause();
          }
          setAudio(null);
        };
    }, []);

    React.useEffect(() => {
        if (isUserChoiceMade) {
            setAnimate(true);
            if (audio) {
                if (isPlaying) {
                  audio.pause();
                  setIsPlaying(false);
                }
            }
            setTimeout(() => {
                setAnimate(false);
                 setCard(prevState => ({
                    ...prevState,
                    currentCard: prevState.nextCard,
                    nextCard: cardsData[1]
                })); 
                setCardClasses({
                    firstCardClass: secondCardClass,
                    secondCardClass: firstCardClass
                });

            },200)
        }
    },[cardsData]);

    React.useEffect(()=> {
        if(writedWord.size === splitWithNikud(currentCard[wordAnswer]).length) {
            const typedWord = Array.from(writedWord.values()).join("");
            enterWord(typedWord);
        };
    }, [writedWord]);
    

    function clickOnLetter(letter, index) {
        console.log("clickOnLetter writedWord letter, index", letter, index);
        setWritedWord(prevState => {
            const newState = new Map(prevState);
            if (newState.has(index)) {
                newState.delete(index);
            } else {
                newState.set(index, letter);
            }
            console.log("clickOnLetter writedWord newState", newState);
            return newState;
        });
    }
    
    function splitWithNikud(text) {
        const nikudRange = /[\u0591-\u05C7]/;
        const result = [];
        let current = '';
    
        for (let char of text) {
            if (nikudRange.test(char)) {
                current += char;
            } else {
                if (current) {
                    result.push(current);
                }
                current = char;
            }
        }
    
        if (current) {
            result.push(current);
        }
    
        return result;
    }

    function enterWord(answer) {
        if (!isChecked) {
        console.log("enterWord answer", answer);
        console.log("enterWord currentCard[wordAnswer]", currentCard[wordAnswer]);
        const isCorrect = answer === currentCard[wordAnswer];
        console.log("isCorrect", isCorrect);
        setCorrectAnswer(isCorrect);
        setIsChecked(true);
        setActiveAnswer(
            {
                card: currentCard,
                isCorrectAnswer: isCorrect,
                typeTraining: type
            }
        );
        if (!isCorrect) {
            setShakedWord(splitWithNikud(currentCard[wordAnswer]).reverse());
            shakedContainer.current.classList.add('correct');
        }
        }
    }

    function genShakedWord(currentWord) {
        let wordArray = splitWithNikud(currentWord);
        let shakedWordArr = wordArray.sort(() => 0.5 - Math.random());
        return shakedWordArr;
    }

    async function playingSound(srcAudio) {
        if (!audio || !srcAudio) return;
    
        audio.src = srcAudio;
    
        if (isPlaying) {
            audio.pause();
            if (audioResolve) {
                audioResolve();
                setAudioResolve(null);
            }
            return audioPromise;
        } else {
            const newAudioPromise = new Promise((resolve, reject) => {
                const handleEnd = () => {
                    setIsPlaying(false);
                    audio.removeEventListener('ended', handleEnd);
                    audio.removeEventListener('pause', handleEnd);
                    resolve();
                };
    
                audio.addEventListener('ended', handleEnd);
                audio.addEventListener('pause', handleEnd);
    
                setAudioResolve(() => resolve);
            });
    
            setAudioPromise(newAudioPromise);
            audio.play();
            setIsPlaying(true);
    
            return newAudioPromise;
        }
    }

    async function handleAudioClick(srcAudio) {
        if (currentAudioPromise) {
            await currentAudioPromise;  // Ждём завершения текущего воспроизведения
        }
        await playingSound(srcAudio);
    }


    const classNameFirstCard = firstCardClass + hide + (isAnimate && firstCardClass == 'currentCard' ? ' animate' : '');
    const classNameSecondCard = secondCardClass + hide + (isAnimate && secondCardClass == 'currentCard' ? ' animate' : '');


    return (

        <div className="card" style={settings.inverse ? {flexDirection: "row-reverse"} : {}}>
                        <div className="left-side">
                    {firstCardClass === 'currentCard' || typeof(nextCard) !== "undefined" ? (
                    <div className={"card--content " + classNameFirstCard}>
                        <div className="card-img--wrapper" ref={firstCardClass == 'currentCard' ? wrapperRef : null}>
                            <div className="card-img--content">
                            <img ref={firstCardClass == 'currentCard' ? imageRef : imageRef2} alt="preview" src={cards[firstCardClass]?.["img"]} style={{
          height: "auto", 
          width: "100%", 
          objectFit: "cover",
          position: "relative",
          top: "0px", 
          left: "0px",
        }}
        />
                            </div>
                        </div>
                        <div className="card-information">
                            <div className="word"><span>{cards[firstCardClass]?.[wordType]}</span></div>
                            <div className={"word-sound" + (isPlaying ? " play" : "")} onClick={()=> handleAudioClick(cards[firstCardClass]?.["sound"])}>
                            {cards[firstCardClass]?.["sound"].length > 0 && (<i className="bx bx-volume-full"></i>)}
                            </div>
                            <div className="transcription"><span>{cards[firstCardClass]?.["transcription"].length ? "[" + cards[firstCardClass]["transcription"] + "]" : null}</span></div>
                        </div>
                    </div>
                    ) : null}
                    {secondCardClass === 'currentCard' || typeof(nextCard) !== "undefined" ? (
                        <div className={"card--content " + classNameSecondCard}>
                        <div className="card-img--wrapper" ref={secondCardClass == 'currentCard' ? wrapperRef : null}>
                            <div className="card-img--content">
                            <img alt="preview" ref={secondCardClass == 'currentCard' ? imageRef : imageRef2} src={cards[secondCardClass]?.["img"]} style={{
          height: "auto", 
          width: "100%", 
          objectFit: "cover",
          position: "relative",
          top: "0px", 
          left: "0px",
        }}
        />
                            </div>
                        </div>
                        <div className="card-information">
                            <div className="word"><span>{cards[secondCardClass]?.[wordType]}</span></div>
                            <div className={"word-sound" + (isPlaying ? " play" : "")}  onClick={()=> handleAudioClick(cards[secondCardClass]?.["sound"])}>
                            {cards[secondCardClass]?.["sound"].length > 0 && (<i className="bx bx-volume-full"></i>)}
                            </div>
                            <div className="transcription"><span>{cards[secondCardClass]?.["transcription"].length ? "[" + cards[secondCardClass]["transcription"] + "]" : null}</span></div>
                        </div>
                    </div>
                    ): null}
                        </div>
                        <div className="right-side">
                            <div className="shaked-word">
                                <div className="shaked-title"><span>Соберите слово из букв</span></div>
                                <div className="writed--container">
                                    {Array.from(writedWord.values()).reverse().map((letter)=> (
                                    <div key={"w_"+letter} className={"letter" + (correctAnswer === false ? " wrong" : correctAnswer === true ? " correct" : "")} ><span style={{margin: "0 5px"}}>{letter}</span></div>)
                                    )}
                                </div>
                                <div className="shaked--container" ref={shakedContainer}>
                                    {shakedWord.map((letter, index)=> (
                                    <div key={index+letter} className={"letter" + (writedWord.has(index) && writedWord.get(index) === letter ? " choised" : "")} onClick ={()=> clickOnLetter(letter, index)} ><span style={{margin: "0 5px"}}>{letter}</span></div>)
                                    )}
                                </div>
                            </div>
                        </div>
        </div>
            );
};

export default BlockTrainingWriting;