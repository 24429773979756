const updateTrainingStat = (stat) => ({
    type: "UPDATE_TRAINING_CARD",
    payload: stat
});

const setUserStatCards =(statCards) => ({
    type: "SET_STAT_CARDS",
    payload: statCards
})

export { updateTrainingStat, setUserStatCards };