import $api from '../http/api';
export default class AuthService {
    static async login(email, password) {
        return $api.post('/user/login', {email, password});
    }
    static async register(email, password) {
        return $api.post('/user/register', {email, password});
    }

    static async logout() {
        return $api.post('/user/logout');
    }
}