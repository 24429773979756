import React, { useState, useRef } from "react";
import $api from "../http/api";
import {updateGroupInfo} from "../redux/actions/cards.js";
import {useDispatch} from 'react-redux';
import {PreviewImage, PlayAudioCard, MiniSearchCards} from './';
import {useMessage} from '../components/service-components/MessageContext.jsx';

function AddCardForm({ groupId, toggleModal }) {
  const dispatch = useDispatch();

    const [previewUrl, setPreviewUrl] = useState(null);
    const [previewAudio, setPreviewAudio] = useState(null);
    const imgInputRef = useRef(null);
  
    const imageRef = useRef(null);
    const audioRef = useRef(null);
    const formRef = useRef(null);
    const [bounds, setBounds] = useState({});
    const [formMessage, setformMessage] = useState('');
    const [searched, setSearched] = useState({cards: [], isVisible: false});
    const [choiseSearchCard, setChoiseSearchCard] = useState(null);
    const [isSearchCard, setIsSearchCard] = useState(false);
    const {addMessage} = useMessage();
  
    const [formData, setFormData] = useState({
        word: '',
        translate: '',
        transcription: '',
        img: "",
        sound: "",
        type: ""
      });

  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }; 
  
    const handleImgChange = (e) => {
      const selectedImg = e.target.files[0];
      if (selectedImg) {
        setFormData((prevData) => ({
          ...prevData,
          img: selectedImg
        }));
        setPreviewUrl(URL.createObjectURL(selectedImg));
      }
    };
  
    const handleAudioChange = (e) => {
      console.log("handleAudioChange");
      const selectedAudio = e.target.files[0];
      console.log("selectedAudio name", selectedAudio.name);
      if (selectedAudio) {
        setFormData((prevData) => ({
          ...prevData,
          sound: selectedAudio,
        }));
        setPreviewAudio(URL.createObjectURL(selectedAudio));
      }
    };
  
    const handleImgButtonClick = () => {
      imgInputRef.current?.click();
    };
  
    const handleAudioButtonClick = () => {
      audioRef.current?.click();
    };
  
    React.useEffect(() => {
      console.log("FormData from AddCard ", formData);
    }, [formData]);
  
    const resizeImg = () => {
      return new Promise((resolve, reject) => {
          if (!formData.img || !previewUrl) return;
          const imageBounds = bounds.image;
  
          const img = new Image();
          const imageUrl = URL.createObjectURL(formData.img);
          img.src = imageUrl;
  
          img.onload = () => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
  
              // Увеличиваем размер холста для улучшения качества
              const scaleFactor = 4;
              canvas.width = imageBounds.width * scaleFactor;
              canvas.height = imageBounds.height * scaleFactor;

              ctx.imageSmoothingEnabled = true; // Включает сглаживание
              ctx.imageSmoothingQuality = 'high'; // Устанавливает качество сглаживания
  
              // Рендерим изображение в увеличенном масштабе
              ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  
              // Уменьшаем холст до нужных размеров
              const reducedCanvas = document.createElement('canvas');
              reducedCanvas.width = imageBounds.width*2;
              reducedCanvas.height = imageBounds.height*2;
              const reducedCtx = reducedCanvas.getContext('2d');
  
              reducedCtx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, reducedCanvas.width, reducedCanvas.height);
  
              reducedCanvas.toBlob((blob) => {
                  const newImage = new File([blob], formData.img.name, {
                      type: 'image/png',
                  });
                  resolve(newImage);
              }, 'image/png');
  
              URL.revokeObjectURL(imageUrl);
          };
  
          img.onerror = () => {
              console.error('Не удалось уменьшить изображение');
              URL.revokeObjectURL(imageUrl);
              reject("Не удалось уменьшить изображение");
          };
      });
  }
  
  
  


    const handleSubmit = async (e) => {
      e.preventDefault();
      let formdata = new FormData();
      if (choiseSearchCard) {
        formdata.append('card_id', choiseSearchCard);
      }
      else {
        if (formData.img !== "") {
          const resizedImage = await resizeImg();
        formData.img = resizedImage;
        formData.img_data = {img_w: bounds.image.width, img_h: bounds.image.height, img_dcx: bounds.image.dcx, img_dcy: bounds.image.dcy};
        }
        formdata.append('new_card', JSON.stringify(formData));
        formdata.append('sound', formData.sound);
        formdata.append('img', formData.img);
      }
      $api.post(`/addCardToGroup/${groupId}`, formdata)
        .then((response) => {
          console.log('Успешно отправлено:', response.data);
          setformMessage("Карточка добавлена");
          dispatch(updateGroupInfo(response.data.group_info));
          addMessage({type: "success", text: "Карточка добавлена"});
          toggleModal();
        })
        .catch((error) => {
          const text = error.response?.data?.message ? error.response.data.message : error.message;
          addMessage({type: "error", text: text});
        });
    };
  
    return (
      <div className="card addCardForm" style={{ display: "flex", justifyContent: "center" }}>
        <div className="left-side" style={{ position: "relative" }}>
          <div className="card--content" style={{width: "100%"}}>
            <PreviewImage previewUrl={previewUrl} formData={formData} isSearchCard={isSearchCard} setBounds={setBounds} bounds={bounds} choiseSearchCard={choiseSearchCard} />
            <div className="card-information">
              <div className="word"><span>{formData.word || "Слово"}</span></div>
              <div className="transcription"><span>{("[" + (formData.transcription || "транскрИпция") + "]")}</span></div>
              <PlayAudioCard sound={previewAudio}/>
              <div className="translate"><span>{formData.translate || "Перевод"}</span></div>
            </div>
          </div>
        </div>
        <div className="right-side" ref={formRef}>
          <form onSubmit={handleSubmit}>
            <div className="form--item">         
              <button onClick={handleImgButtonClick} type="button">Загрузить картинку</button>
              <input
                type="file" name="img" style={{ display: "none" }} ref={imgInputRef} onChange={handleImgChange} accept="image/*,image/jpeg"
              />
            </div>
            <div className="form--item">
              <label>Слово на иврите</label>
              <input
                type="search" name="word" readOnly={isSearchCard} value={formData.word} onChange={handleChange} required
              />
              <MiniSearchCards choiseSearchCard={choiseSearchCard} setChoiseSearchCard={setChoiseSearchCard} searchCard={formData.word} searched={searched} setSearched={setSearched} setFormData={setFormData} setPreviewUrl={setPreviewUrl} setIsSearchCard={setIsSearchCard}/>
            </div>
            <div className="form--item">
              <label>Тип карточки</label>
              <select name="type" onChange={handleChange} value={formData.type} readOnly={isSearchCard} required>
                <option value='' selected disabled >Выберите тип карточки</option>
                <option value='symbol' selected={formData.type=='symbol'}>Буква</option>
                <option value='word' selected={formData.type=='word'}>Слово</option>
                <option value='phrase' selected={formData.type=='phrase'}>Фраза</option>
                <option value='expression' selected={formData.type=='expression'}>Предложение</option>
              </select>
            </div>
            <div className="form--item">
              <label>Перевод</label>
              <input
               type="text" name="translate" readOnly={isSearchCard} value={formData.translate} onChange={handleChange} required
              />
            </div>
            <div className="form--item">
              <label>Транскрипция</label>
              <input type="text" name="transcription" readOnly={isSearchCard} value={formData.transcription} onChange={handleChange}
              />
            </div>
            <div className="form--item">         
              <button onClick={handleAudioButtonClick} type="button">Загрузить озвучку</button>
              <label><p style={{fontSize:"12px"}}>{formData?.sound?.name}</p></label>
              <input
                  type="file" name="audio" style={{ display: "none" }} ref={audioRef} onChange={handleAudioChange} accept="audio/*,audio/mp3,audio/waw"
              />
            </div>
          <div style={{display:"flex", gap:"10px", alignItems:"center"}}>
            <button type="submit">Добавить</button>
            <div className="form--message">{formMessage}</div>
          </div>
      </form>
    </div>
  </div>
  );
}

  export default AddCardForm;