import React, { useState, useEffect, useRef } from 'react';
const TooltipProvider = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [tooltipText, setTooltipText] = useState('');
    const [position, setPosition] = useState({ top: 0, left: 0 });

    const tooltipRef = useRef(null);
    const [tooltipWidth, setTooltipWidth] = useState(0);

    useEffect(() => {
      if (tooltipRef.current) {
        setTooltipWidth(tooltipRef.current.offsetWidth);
      }
    },[isVisible])

    useEffect(() => {
      console.log("tooltipWidth", tooltipWidth);
    },[tooltipWidth])
  
    const handleMouseMove = (e) => {
      const tooltipTarget = e.target.closest('[data-tooltip]');
      if (!tooltipTarget) { 
        setIsVisible(false);
        return;
      }
  
      const { top, left, height, width } = tooltipTarget.getBoundingClientRect();
      setPosition({
        top: top + window.scrollY - height/2 - 20,
        left: left + window.scrollX + width/2
      });
      setTooltipText(tooltipTarget.getAttribute('data-tooltip'));
      setIsVisible(true);
    };
  
    const handleMouseOut = (e) => {
      if (!e.target.closest('[data-tooltip]')) {
        setIsVisible(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseout', handleMouseOut);
  
      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseout', handleMouseOut);
      };
    }, []);
  
    return isVisible ? (
      <div ref={tooltipRef} style={{
        position: 'absolute',
        minWidth: '100px',
        top: position.top,
        left: `calc(${position.left}px - ${tooltipWidth/2}px)`,
        fontSize: '12px',
        whiteSpace: 'nowrap',
        textAlign: 'center',
        zIndex: 1000,
        padding: '8px',
        backgroundColor: '#232343',
        color: 'white',
        borderRadius: '4px',
        pointerEvents: 'none'
      }}>
        {tooltipText}
      </div>
    ) : null;
  };

  export default TooltipProvider;