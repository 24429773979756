import React, {useState, useEffect} from "react";
import { Switch, Route, Routes, useLocation, Link } from 'react-router-dom';
import {AdminMenu, PlatformGroups} from "../../components/admin";
import '../../admin.css';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {useMessage} from '../../components/service-components/MessageContext.jsx';
import CheckUserRole from "../../components/service-components/CheckUserRole.jsx";
const allowedRoles = ["admin", "manager", "teacher"];

const menu = [{name: "Дашборд", link: "/admin-p"}, {name:"Наборы", link: "/admin-p/groupcards"}, {name: "Карточки", link: "/admin-p/cards"}, {name: "Пользователи", link: "/admin-p/users"}, {name: "Платформа", link: "/admin-p/platform"}]

 function AdminPanel() {

    const {addMessage} = useMessage();
    const user = useSelector(state => state.user);
    console.log("admin panel user state", user);
    const navigate = useNavigate();
    const userLocStorage = localStorage.getItem('userData');
    const userData = (userLocStorage && userLocStorage !== 'undefined') ? JSON.parse(userLocStorage) : null;
    const currentPage = useLocation();
    const [navbarActiveItem, setNavbarActiveItem] = useState(currentPage.pathname);

    useEffect(() => {
        if (!user.load) return;
        if (user.isAuth) {
            if (allowedRoles.length > 0 && !allowedRoles.includes(user.userData.role)) {
                addMessage({ type: "error", text: "Доступ закрыт" });
                navigate("/login");
            }
        }
        else navigate("/login");
    }, [user.load]);

    return (
            <body className="admin">
                <header>
                    <div>
                        <Link to={'/'} style={{fontSize: "18px", display: "flex", alignItems: "center", gap: "8px"} }>
                            <span style={{color: "#FFFFFF"}}>Вернуться на сайт</span>
                            <i class='bx bx-link-external' style={{color: "#FFFFFF"}}></i>
                        </Link>
                    </div>
                </header>
                <div className="container">
                    <navbar>
                        <div className="navbar--wrapper">
                            {menu.map((item) => (
                                <Link to={item.link}><div key={item.name} className={`navbar--item ${item.link === navbarActiveItem ? `active` : ''}`} onClick={() => setNavbarActiveItem(item.link)}>{item.name}</div></Link>
                            ))}
                        </div>
                    </navbar>
                    <content>
                        <Routes>
                            <Route path="/admin-p/platform" element={<PlatformGroups/>}/>
                        </Routes>
                    </content>
                </div>
            </body>
    )
}

export default AdminPanel;