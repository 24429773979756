const setCards = (cards) => ({
    type: "SET_CARDS",
    payload: cards
});

const setGroupsCards = (groups) => ({
    type: "SET_GROUPS_CARDS",
    payload: groups
});

const updateGroupInfo = (groupInfo) => ({
    type: "UPDATE_GROUP_INFO",
    payload: groupInfo
});

const addNewGroup = (groupInfo) => ({
    type: "ADD_NEW_GROUP",
    payload: groupInfo
});

export { setCards, setGroupsCards, updateGroupInfo, addNewGroup };